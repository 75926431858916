<script>
  import lodash from 'lodash';
  import controller from '@/controller';
  import { buildPostFromFirestore } from '@/util/buildPostFromFirestore';
  import { formatTweet } from '@/util/formatTweet';
  import { isBottomOfPage } from '@/util/isBottomOfPage';
  import { store } from '@/store';
  import moment from 'moment';
  import GetHypefurySubscriptionMixin from './GetHypefurySubscriptionMixin.vue';
  import CustomerStatusMixin from './CustomerStatusMixin.vue';

  const fb = require('../../firebase');

  /**
   * The way we use Firestore' onSnapshot doesn't keep the order of the documents.
   * These utils are used to sort the documents after onSnapshot is run.
   */
  const scoreUtils = {
    computeIdsToScore(hits) {
      return lodash.keyBy(
        hits.map((hit) => {
          return {
            id: hit._source.id,
            score: hit._score,
          };
        }),
        'id'
      );
    },
    _docsToDocsWithScore(docs, idsToScore) {
      return docs.map((doc) => {
        return {
          doc,
          score: idsToScore[doc.id].score,
        };
      });
    },
    sortDocumentsByScore(docs, idsToScore) {
      const docsWithScore = this._docsToDocsWithScore(docs, idsToScore);
      return lodash
        .sortBy(docsWithScore, 'score')
        .map((doc) => doc.doc)
        .reverse();
    },
  };

  export default {
    computed: {
      threads: {
        cache: false,
        get() {
          const that = this;
          if (!this.postsFromCursors[this.filter]) return [];
          const posts = this.postsFromCursors[this.filter].map((docs) => {
            return docs
              .filter((d) => {
                let isTimeValid = false;
                const time = d.data().time;
                const isRecurrentPost = d.data().isRecurrentPost;
                isTimeValid = !lodash.isNil(time);

                // For categories: filter out drafts with categories that aren't recurrent posts
                return that.categoryId ? time || isRecurrentPost : isTimeValid;
              })
              .map((d) => buildPostFromFirestore(d, this.userProfile.timezone));
          });
          const postsMap = {};
          const flattenedPosts = lodash.flatten(posts);
          flattenedPosts
            .filter((p) => p != null)
            .forEach((p) => {
              postsMap[p.id] = p;
            });
          return Object.values(postsMap);
        },
      },
    },
    created() {
      this.filter = 'all';
    },
    data() {
      return {
        filter: null,
        firstBatchOfPostsLoaded: false,
        isScheduleRetweetModalVisible: false,
        isScheduleRetweetRecurrentlyModalVisible: false,
        postsFromCursors: { all: [] },
        retweetToSchedule: null,
        searchInput: null,
        searchQueries: {},
        timeRange: null,
        listOfSnapshotsUnsubscriptions: [],
      };
    },
    methods: {
      closeScheduleRetweetModal: function () {
        this.isScheduleRetweetModalVisible = false;
        this.isScheduleRetweetRecurrentlyModalVisible = false;
      },
      onScroll() {
        window.onscroll = () => {
          if (isBottomOfPage() && !this.loadingMorePosts) {
            this.loadMorePosts();
          }
        };
      },
      loadMorePosts(hasNoPostsOnFirstLoad) {
        this.loadingMorePosts = true;

        if (!this.filter.startsWith('search:')) {
          const cursorsCount = this.postsFromCursors[this.filter].length;
          const lastRetrievedDoc = lodash.last(
            this.postsFromCursors[this.filter][cursorsCount - 1]
          );

          if (!lastRetrievedDoc && !hasNoPostsOnFirstLoad) {
            return;
          }

          let query = this.createPostsQuery(cursorsCount)[this.filter];

          if (
            this.userProfile.customerStatus === 'standard' &&
            !this.timeRange &&
            !this.categoryId
          ) {
            const startOfCurrentWeek = moment().startOf('week');
            query = query.where('time', '<', startOfCurrentWeek.toDate());
          }

          if (['all', 'deleted'].includes(this.filter) && !this.timeRange && !this.categoryId) {
            query = query.orderBy('time', 'desc').limit(10);
          }

          if (!hasNoPostsOnFirstLoad && !this.categoryId) {
            query = query.startAfter(lastRetrievedDoc);
          }

          const unsubscribe = query.onSnapshot((doc) => {
            this.loadingMorePosts = false;

            if (lodash.isNil(this.postsFromCursors[this.filter][cursorsCount])) {
              this.postsFromCursors[this.filter].push([]);
            }

            this.$set(this.postsFromCursors[this.filter], cursorsCount, doc.docs);
          });

          this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
          store.dispatch('addFirestoreListener', unsubscribe);
        } else {
          this.createPostsQuery()[this.filter].then((queries) => {
            const idsToScore = scoreUtils.computeIdsToScore(queries[0].hits);
            queries.forEach((query, i) => {
              if (query.processed) {
                this.loadingMorePosts = false;
                return;
              }

              const unsubscribe = query.query.onSnapshot((result) => {
                this.loadingMorePosts = false;
                const sortedDocs = scoreUtils.sortDocumentsByScore(result.docs, idsToScore);
                this.$set(this.postsFromCursors[this.filter], i, sortedDocs);
                query.processed = true;
                this.$forceUpdate();
              });

              this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
              store.dispatch('addFirestoreListener', unsubscribe);
            });
          });
        }
      },
      processFilterChange(filter) {
        if (!filter.startsWith('search:')) {
          this.firstBatchOfPostsLoaded = false;
          let query = this.createPostsQuery()[filter];

          if (
            this.userProfile.customerStatus === 'standard' &&
            !this.timeRange &&
            !this.categoryId
          ) {
            const startOfCurrentWeek = moment().startOf('week');
            query = query
              .where('source', 'in', [
                'hypefury-app',
                'csv',
                'linkedin-post',
                'facebook-post',
                'instagram-post',
                'threads-post',
                'tiktok-post',
              ])
              .where('time', '>', startOfCurrentWeek.toDate())
              .orderBy('time', 'desc');
          } else if (
            ['all', 'deleted'].includes(this.filter) &&
            !this.timeRange &&
            !this.categoryId
          ) {
            query = query.orderBy('time', 'desc').limit(10);
          }

          const unsubscribe = query.onSnapshot((doc) => {
            this.firstBatchOfPostsLoaded = true;

            if (lodash.isNil(this.postsFromCursors[filter][0])) {
              this.postsFromCursors[filter].push([]);
            }

            this.$set(this.postsFromCursors[filter], 0, doc.docs);

            if (doc.docs.length === 0) {
              this.loadMorePosts(true);
            } else if (doc.docs.length < 10) {
              this.loadMorePosts(false);
            }
          });

          this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
          store.dispatch('addFirestoreListener', unsubscribe);
        } else {
          this.firstBatchOfPostsLoaded = false;
          this.createPostsQuery()[filter].then((queries) => {
            if (!queries || queries[0].processed) {
              return;
            }

            const idsToScore = scoreUtils.computeIdsToScore(queries[0].hits);

            const unsubscribe = queries[0].query.onSnapshot((result) => {
              this.firstBatchOfPostsLoaded = true;
              if (lodash.isNil(this.postsFromCursors[filter][0]))
                this.postsFromCursors[filter].push([]);
              const sortedDocs = scoreUtils.sortDocumentsByScore(result.docs, idsToScore);
              this.$set(this.postsFromCursors[filter], 0, sortedDocs);
              queries[0].processed = true;
              this.$forceUpdate();
            });

            this.listOfSnapshotsUnsubscriptions.push(unsubscribe);
            store.dispatch('addFirestoreListener', unsubscribe);
          });
        }
      },
      searchQuery(keyword) {
        return controller
          .searchForKeyword(
            this.currentUser,
            this.userProfile,
            this.searchInput.isFavorite,
            keyword
          )
          .then((hits) => {
            if (this.userProfile.customerStatus === 'standard') {
              const startOfCurrentWeek = moment().startOf('week');
              hits = hits.filter((h) => {
                const isValidStandardUserPost = [
                  'hypefury-app',
                  'csv',
                  'linkedin-post',
                  'facebook-post',
                  'instagram-post',
                  'threads-post',
                  'tiktok-post',
                ].includes(h._source.source);
                const postIsBeforeThisWeek = moment(h._source.createdAt).isBefore(
                  startOfCurrentWeek
                );
                return isValidStandardUserPost || postIsBeforeThisWeek;
              });
            }
            if (hits.length === 0) {
              this.firstBatchOfPostsLoaded = true;
              return;
            }
            const chunkedHits = lodash.chunk(hits, 10);
            const queries = chunkedHits.map((hits) => {
              const ids = hits.map((h) => h._source.id);
              let query = fb.threadsCollection
                .where('deleted', '==', false)
                .where(fb.firebase.firestore.FieldPath.documentId(), 'in', ids);
              if (this.categoryId) {
                query = query
                  .where(
                    'categories',
                    'array-contains',
                    fb.categoriesCollection.doc(this.categoryId)
                  )
                  .where('isCloned', '==', false);
              }
              return query;
            });
            return queries.map((query) => {
              return { query, processed: false, hits };
            });
          });
      },
      resetPostFromCursors() {
        this.postsFromCursors = {
          all: [],
          deleted: [],
          retweets: [],
          likes: [],
          impressions: [],
          replies: [],
          bookmarks: [],
          profileClicks: [],
          hypefuryTweets: [],
          disabled: [],
        };
      },
      retweet(thread) {
        const self = this;
        return controller
          .retweet(this.currentUser, thread.id, this.userProfile.uid)
          .then(() => {
            self.$notify({ type: 'success', message: 'Tweet successfully retweeted!' });
          })
          .catch((error) => {
            if (error.response.status === 404) {
              self.$notify({
                type: 'warning',
                message: 'Tweet not found. Did you maybe delete it from Twitter?',
              });
            } else {
              self.$notify({
                type: 'danger',
                message: "An unknown error has occurred. Couldn't retweet.",
              });
            }
          });
      },
      scheduleRetweet(post) {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp('Schedule Retweet', 'schedule a retweet');
        } else {
          this.retweetToSchedule = post;
          this.isScheduleRetweetModalVisible = true;
        }
      },
      formatTweet(status) {
        return formatTweet(status);
      },
      scheduleRetweetRecurrently(post) {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Schedule Retweet Recurrently',
            'schedule a retweet recurrently',
          );
        } else {
          this.retweetToSchedule = post;
          this.isScheduleRetweetRecurrentlyModalVisible = true;
        }
      },
      updateFilter(value) {
        this.filter = value;
      },
      updateSearchInput(value) {
        this.searchInput = value;
      },
      updateTimeRange(value) {
        this.timeRange = value;
        this.postsFromCursors[this.filter] = [];
        this.loadingMorePosts = false;

        if (this.filter === null) {
          return;
        }

        this.processFilterChange(this.filter);
      },
      resetTimeRange() {
        this.timeRange = null;
        this.updateTimeRange(this.timeRange);
      },
    },
    name: 'history-mixin',
    watch: {
      isScheduleRetweetModalVisible() {
        this.$forceUpdate();
      },
      filter(filter) {
        if (filter === null) {
          return;
        }

        this.processFilterChange(filter);
      },
      'searchInput.keyword': lodash.debounce(function (val) {
        if (val.length < 3) {
          this.filter = 'all';
          return;
        }

        const filter = `search:${val}`;

        this.searchQueries[filter] = this.searchQuery(val);
        this.postsFromCursors[filter] = [];
        this.filter = filter;
      }, 200),
    },
    beforeDestroy() {
      this.listOfSnapshotsUnsubscriptions.forEach((unsubscribe) => {
        if (!unsubscribe) return;
        unsubscribe();
      });
    },
    mixins: [GetHypefurySubscriptionMixin, CustomerStatusMixin],
  };
</script>
