<template>
  <div>
    <tooltip
      :content="`Grab a ${getPlanLabel(
        'standard'
      )} Hypefury subscription to see your tweets' statistics`"
      v-if="userProfile.customerStatus === 'none'"
    >
      <div class="space-x-3 flex items-center relative" style="filter: blur(3px)">
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/new-retweet.svg" class="w-5" />
          <span data-cy="metric-retweets-count">1</span>
          <span v-if="thread.tweets[0].quoteCount >= 0">
            / 1
          </span>
        </div>
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/like.svg" class="w-5" />
          <span data-cy="metric-likes-count">30</span>
        </div>
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/impressions.svg" class="w-5" />
          <span data-cy="metric-impressions-count">9.7K</span>
        </div>
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/reply.svg" class="w-5" />
          <span data-cy="metric-replies-count">5</span>
        </div>
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/new/bookmark.svg" class="w-5" />
          <span data-cy="metric-bookmarks-count">5</span>
        </div>
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/profile_clicks.svg" class="w-5" />
          <span data-cy="metric-profile-clicks-count">34</span>
        </div>
      </div>
    </tooltip>
  <div class="space-x-3 flex items-center relative" v-else>
    <div class="flex flex-col space-y-1.5 w-full " v-if="recurrentPostsMetrics[thread.id]">
      <div class="flex gap-x-3 w-full " v-for="(val, key) in recurrentPostsMetrics[thread.id] " :key="key">
        <span class="w-12">{{ key.charAt(0).toUpperCase() + key.slice(1) }}:</span>
        <tooltip
          :content="`Retweets ${val.quoteCount >= 0 ? '/ Quotes' : ''}`"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.retweetCount >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/new-retweet.svg" class="w-5" />
            <span data-cy="metric-retweets-count">{{ countFormatter(val.quoteCount, 1) }}</span>
            <span v-if="val.quoteCount >= 0">
              / {{ countFormatter(val.quoteCount, 1) }}
            </span>
          </div>
        </tooltip>
        <tooltip
          content="Likes"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.favoriteCount >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/like.svg" class="w-5" />
            <span data-cy="metric-likes-count">{{
              countFormatter(val.favoriteCount, 1)
            }}</span>
          </div>
        </tooltip>
        <tooltip
          content="Impressions"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.impressions >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/impressions.svg" class="w-5" />
            <span data-cy="metric-impressions-count">{{
              countFormatter(val.impressions, 1)
            }}</span>
          </div>
        </tooltip>
        <tooltip
          content="Replies"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.replyCount >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/reply.svg" class="w-5" />
            <span data-cy="metric-replies-count">{{
              countFormatter(val.replyCount, 1)
            }}</span>
          </div>
        </tooltip>
        <tooltip
          content="Bookmarks"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.bookmarkCount >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/new/bookmark.svg" class="w-5" />
            <span data-cy="metric-bookmark-count">{{
              countFormatter(val.bookmarkCount, 1)
            }}</span>
          </div>
        </tooltip>
        <tooltip
          content="Profile Clicks"
          :footer="getTweetMetricsUpdatedAtText"
          v-if="val.userProfileClicks >= 0"
        >
          <div class="flex items-center gap-1 text-sm">
            <inline-svg src="/img/icons/profile_clicks.svg" class="w-5" />
            <span data-cy="metric-profile-clicks-count">{{
              countFormatter(val.userProfileClicks, 1)
            }}</span>
          </div>
        </tooltip>
      </div>
    </div>
    <template v-else>
      <tooltip
        :content="`Retweets ${thread.tweets[0].quoteCount >= 0 ? '/ Quotes' : ''}`"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].retweetCount >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/new-retweet.svg" class="w-5" />
          <span data-cy="metric-retweets-count">{{
            countFormatter(thread.tweets[0].retweetCount, 1)
          }}</span>
          <span v-if="thread.tweets[0].quoteCount >= 0">
            / {{ countFormatter(thread.tweets[0].quoteCount, 1) }}
          </span>
        </div>
      </tooltip>
      <tooltip
        content="Likes"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].favoriteCount >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/like.svg" class="w-5" />
          <span data-cy="metric-likes-count">{{
            countFormatter(thread.tweets[0].favoriteCount, 1)
          }}</span>
        </div>
      </tooltip>
      <tooltip
        content="Impressions"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].impressions >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/impressions.svg" class="w-5" />
          <span data-cy="metric-impressions-count">{{
            countFormatter(thread.tweets[0].impressions, 1)
          }}</span>
        </div>
      </tooltip>
      <tooltip
        content="Replies"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].replyCount >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/reply.svg" class="w-5" />
          <span data-cy="metric-replies-count">{{
            countFormatter(thread.tweets[0].replyCount, 1)
          }}</span>
        </div>
      </tooltip>
      <tooltip
        content="Bookmarks"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].bookmarkCount >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/new/bookmark.svg" class="w-5" />
          <span data-cy="metric-bookmarks-count">{{
            countFormatter(thread.tweets[0].bookmarkCount, 1)
          }}</span>
        </div>
      </tooltip>
      <tooltip
        content="Profile Clicks"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].userProfileClicks >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/profile_clicks.svg" class="w-5" />
          <span data-cy="metric-profile-clicks-count">{{
            countFormatter(thread.tweets[0].userProfileClicks, 1)
          }}</span>
        </div>
      </tooltip>
      <tooltip
        content="URL Clicks"
        :footer="getTweetMetricsUpdatedAtText"
        v-if="thread.tweets[0].urlLinkClicks >= 0"
      >
        <div class="flex items-center gap-1 text-sm">
          <inline-svg src="/img/icons/url_clicks.svg" class="w-5" />
          <span>{{ countFormatter(thread.tweets[0].urlLinkClicks, 1) }}</span>
        </div>
      </tooltip>
      <tooltip content="Growth Program" v-if="thread.source == 'growth-program'">
        <div class="space-x-1">
          <inline-svg src="/img/icons/growth.svg" class="w-5" />
        </div>
      </tooltip>
    </template>
  </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { countFormatter } from '@/util/countFormatter';
  import moment from 'moment';
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'tweets-metrics-card-footer',
    props: {
      thread: {
        type: Object,
      },
      recurrentPostsMetrics: {
        type: Object,
        default: () => ({}),
      }
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      getTweetMetricsUpdatedAtText() {
        if (!this.thread.tweetMetricsUpdatedAt) return;
        const time = moment(
          this.thread.tweetMetricsUpdatedAt.toDate(),
          this.userProfile.timezone
        ).fromNow();
        return `Updated ${time}`;
      },
    },
    methods: {
      countFormatter(num, digits) {
        return countFormatter(num, digits);
      },
    },
    mixins: [CustomerStatusMixin],
  };
</script>
